<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 class="grey1">{{ data.sysText.uploadDocumentTitle | titlecase }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
      <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
    </ng-container>
  </div>
  
  <mat-dialog-content class="mat-dialog-content">
    <ng-container>
    <form [formGroup]="form">
      <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)"/>
      
      <div class="file-input-field">
        <mat-icon class="input-icon">attach_file</mat-icon>
        <button mat-stroked-button color="accent" (click)="imgFileInput.click()" class="body2">
          <mat-icon>upload</mat-icon>
          <span> {{data.sysText.upload | capitalizefirst}} </span>
        </button>
        <div class="pad" *ngIf="fileToUpload">
          <div class="flex-horiz">
            <mat-icon class="fill4-dk check-padding">check</mat-icon>
            <div>
              <span class="body2 side-space grey3">{{ fileToUpload?.name }}</span>
            </div>
            <mat-icon class="black-inactive small pointer" (click)="clearFile()">clear</mat-icon>
          </div>
        </div>
      </div>

      <mat-error class="body 2 upload-error">{{ document.errors?.msError}}</mat-error>

      <app-input-field
        [group]="form"
        [control]="documentTitle"
        [icon]="'description'"
        [label]="data.sysText.documentTitle"
      ></app-input-field>
      <app-select-field
        [group]="form"
        [control]="documentCategoryId"
        [options]="data.docUploadDialog.categories"
        [icon]="'list'"
        [label]="data.sysText.documentCategory"
        [shouldCapitalize]="false"
      ></app-select-field>
      <app-text-area
        [control]="documentComments"
        [label]="data.sysText.documentComments"
        [icon]="'comment'"
      ></app-text-area>
      <div *ngIf="data.mode == docUploadMode.Employee" class="select-container">
        <mat-icon [ngClass]="{ focus: select.focused }">assignment_turned_in</mat-icon>
        <mat-form-field color="accent">
          <mat-label>{{ data.sysText.associatedCase | titlecase }}</mat-label>
          <mat-select #select="matSelect" [formControl]="associatedCase" disableOptionCentering panelClass="dropDownSelect">
            <mat-select-trigger>
              {{associatedCase.value}}
            </mat-select-trigger>
            <mat-option *ngFor="let case of data.docUploadDialog.cases" [value]="case.caseId">
              <span>{{ case.caseId }}</span>
              <span *ngIf="case.leaveType" class="caption option-extras">{{ case.leaveType }}</span>
              <span *ngIf="case.startDate" class="caption option-extras"><span class="bullet-spacing" *ngIf="case.leaveType">&bull;</span>{{ data.sysText.caseLeaveInfo_started | titlecase }} {{case.startDate | date}}</span>
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="data.docUploadDialog.cases.length === 0">{{ data.sysText.noCases }}</mat-hint>
        </mat-form-field>
    </div>
    </form>
  </ng-container>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!(isSaving$ | async); else saving">
      <button
        mat-stroked-button
        class="button"
        color="accent"
        (click)="cancel()"
        [disabled]="isSaving$ | async"
        *ngIf="!isHandheld"
      >
        {{ data.sysText.cancel }}
      </button>
      <button mat-stroked-button class="button" color="accent" (click)="onSubmit()" [disabled]="(isSaving$ | async)">
        {{ data.sysText.submit }}
      </button>
    </ng-container>
    <ng-template #saving>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>
  
