import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelStateErrors, StiiraError } from '@core/models';
import { AbstractControl, FormGroup } from '@angular/forms';
import { nameof } from '@shared/helpers';
import { NotificationService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  private connectionErrorMessage: string = "There seems to be a problem accessing Stiira. This may be a short-term issue. Please wait a couple minutes and try again. If the problem persists, please contact your systems administrator.";
  private contentTooLargeMessage: string = "The selected document exceeds 50 MB in size and cannot be uploaded.";
  private unexpectedCondition: string = "(unexpected condition)";

  get launchErrorMessage(): string {
    return this.connectionErrorMessage;
  }

  get tooLargeMessage(): string {
    return this.contentTooLargeMessage;
  }

  constructor(private notificationService: NotificationService) {}

   setFormModelStateErrors(formGroup: FormGroup, errors: ModelStateErrors, ignoreDisabled: boolean = false): void {
    if (errors) {
      Object.keys(errors).forEach((err) => {
        const formField: AbstractControl = formGroup.get(err);
        if (formField != null) { 
          if (formField.disabled && !ignoreDisabled) {
            // it is not expected that a model state error would be generated 
            // for a disabled control ... since disabled controls will not 
            // display model state errors, this is a failsafe to ensure a 
            // meaningful message is shown in a scenario when a model state 
            // error has been generated for a disabled control 
            this.notificationService.showErrorToast(`${errors[err] ?? err} ${this.unexpectedCondition}`);
          } 
          else if (errors[err] == null) {
            formField.setErrors({
              msError: err,
            });
          } 
          else {
            formField.setErrors({
              msError: errors[err],
            });
          }
          
          formField.markAsTouched();
        } 
        else if (err == '')
          formGroup.setErrors({ msError: errors[err], });
      });
    }
  }

  isLaunchConnectionError(error: HttpErrorResponse): boolean {
    try {
      const modelStateErrorProperty = nameof<StiiraError>('modelStateErrors');
      const globalErrorProperty = nameof<StiiraError>('globalError');  
      const errorString: string = error.error.exceptionMessage || error.error.message || error.error;      
      const errObj: object = JSON.parse(errorString);  
      if (errObj.hasOwnProperty(modelStateErrorProperty)) {
        return false;
      }  
      if (errObj.hasOwnProperty(globalErrorProperty)) {
        return false;
      }      
    }
    catch {}

    if (error.status === 0 
        || error.status === 500 
        || error.error === "The operation has timed out." 
        || error.statusText === "Unknown Error" 
        || error.error === "Unable to connect to the remote server") {
      return true;
    }
    return false;
  }

  createStiiraError(error: HttpErrorResponse): StiiraError {
    let stiiraError: StiiraError = {
      modelStateErrors: null,
      globalError: null,
    };

    const modelStateErrorProperty = nameof<StiiraError>('modelStateErrors');
    const globalErrorProperty = nameof<StiiraError>('globalError');

    try {
      const errorString: string = error.error.exceptionMessage || error.error.message || error.error;
      
      const errObj: object = JSON.parse(errorString);

      if (errObj.hasOwnProperty(modelStateErrorProperty)) {
        stiiraError.modelStateErrors = errObj[modelStateErrorProperty];
      }

      if (errObj.hasOwnProperty(globalErrorProperty)) {
        stiiraError.globalError = errObj[globalErrorProperty];
      }

      if (!stiiraError.modelStateErrors && !stiiraError.globalError) {
        stiiraError.globalError = this.getStatusCodeAndText(error);
        return stiiraError;
      }

      return stiiraError;
    } catch (ex) {
      stiiraError.globalError = this.getStatusCodeAndText(error);
      return stiiraError;
    }
  }

  getStatusCodeAndText(error: HttpErrorResponse): string {
    return `${error.status}: ${error.statusText}`;
  }
}
